import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {IoIosArrowBack} from 'react-icons/io';
import {withRouter} from 'react-router';
import {compact} from 'underscore';

import {urlForBackToSearch} from '../../../lib/support/routing';
import Configuration from '../../../lib/configuration';
import Platform from '../../../lib/platform';
import {IconLink} from './IconLink';

class Page extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    showBacklink: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
    nakedHeading: PropTypes.node,
    heading: PropTypes.node,
  };

  static defaultProps = {
    showBacklink: false,
  };

  componentDidMount() {
    if (this.props.title !== null) {
      this._updateTitle();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.title !== this.props.title) {
      this._updateTitle();
    }
  }

  render() {
    const {heading, nakedHeading, showBacklink} = this.props;
    return (
      <div className={classNames('Page', this.props.className)}>
        {(showBacklink || heading || nakedHeading) && (
          <div className="Page_top">
            {showBacklink && (
              <div className="Page_top_backlink">
                <IconLink
                  icon={IoIosArrowBack}
                  size={20}
                  href={urlForBackToSearch(this.props.location.search)}>
                  <span className="t">Back to search</span>
                </IconLink>
              </div>
            )}
            {heading && <div className="Page_top_heading">{heading}</div>}
            {nakedHeading && <div className="Page_top_naked_heading">{nakedHeading}</div>}
          </div>
        )}
        <div className="Page_content" data-is-scroll-root="true">
          {this.props.children}
        </div>
      </div>
    );
  }

  _updateTitle() {
    const title = this.props.title || '';
    Platform.setViewTitle(compact([title, Configuration.get('title')]).join(' – '));
  }
}

export default withRouter(Page);
