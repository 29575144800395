import React from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'underscore';
import {withRouter} from 'react-router';

import styles from './Ad.module.css';
import Configuration from '../../lib/configuration';
import {createAd} from '../../lib/ads';

const sizes = {
  home_partner_leaderboard: styles.homePartnerLeaderboard,
  home_branded_leaderboard: styles.homeBrandedLeaderboard,
  home_branded_tile: styles.homeBrandedTile,
  homepage_bottom: styles.homepageBottom,
  homepage_story_tile: styles.homepageStoryTile,
  search_results_leaderboard: styles.searchResultsLeaderboard,
  search_results_tile_fixed: styles.searchResultsTileFixed,
  search_results_tile_fluid: styles.searchResultsTileFluid,
  listing_detail_tile: styles.listingDetailTile,
  content_leaderboard: styles.contentLeaderboard,
  content_skyscraper: styles.contentSkyscraper,
};

let adCounter = 0;

class Ad extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    targeting: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const adNumber = adCounter++;
    this._adId = `Ad-${adNumber}`;
    this._config = Configuration.get(`ads.${props.name}`);
  }

  componentDidMount() {
    if (!this._config?.vendor) {
      return;
    }
    this.createAd();
  }

  componentWillUnmount() {
    this.removeAd();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this._config?.vendor) {
      return;
    }
    if (!isEqual(this.props.targeting, prevProps.targeting)) {
      this.createAd();
    }
  }

  createAd = () => {
    this.removeAd();
    const adName = this._config.size ? `${this.props.name}_${this._config.size}` : this.props.name;
    this._cleanup = createAd(
      this._adId,
      this._config.vendor,
      adName,
      this._config.slot,
      this.props.targeting
    );
  };

  removeAd = () => {
    if (this._cleanup) {
      const cleanup = this._cleanup;
      this._cleanup = null;
      cleanup();
    }
  };

  render() {
    const {size} = Configuration.get(`ads.${this.props.name}`) || {};
    const fullName = size ? `${this.props.name}_${size}` : this.props.name;
    const sizeClass = sizes[fullName] || '';
    return <div id={this._adId} className={`${styles.root} ${sizeClass}`}></div>;
  }
}

const TargetAd = ({name, location, extraTargeting = null}) => {
  const targeting = {};
  Object.assign(targeting, extraTargeting);
  return <Ad name={name} targeting={targeting} />;
};

export default withRouter(TargetAd);
