import React from 'react';

import styles from './ListicleTile.module.css';

const ListicleTile = ({title, to, imageUrl}) => (
  <a className={styles.root} href={to}>
    <div>{title}</div>
    <img src={imageUrl} />
  </a>
);

export default ListicleTile;
