import React, {useState, useEffect} from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import moment from 'moment'; // Force self-registration
import L from 'leaflet';

import configureStore from './lib/configureStore';
import Root from './app/components/Root';
import {setupAds} from './lib/ads';
import './lib/models';
import Configuration from './lib/configuration';
import {PageContext} from './lib/pageContext';

function App() {
  const [store, setStore] = useState();
  const [pageContext, setPageContext] = useState();

  useEffect(() => {
    (async () => {
      L.Icon.Default.imagePath = '/images/leaflet/';
      moment.locale('en');

      const siteConfigElem = document.getElementById('site_config');
      const site = JSON.parse(siteConfigElem.textContent);
      Configuration.configure(site);

      const pageContextElem = document.getElementById('page_context');
      const pageContext = JSON.parse(pageContextElem.textContent);
      setPageContext(pageContext);

      // TODO Cleanup old session variables, delete this code after a grace period.
      const sessionStorage = window.sessionStorage;
      sessionStorage.removeItem('sov');
      sessionStorage.removeItem('sov_type');
      sessionStorage.removeItem('sov_agent');
      sessionStorage.removeItem('sov_broker');

      const store = configureStore({});
      setStore(store);
      setupAds();
    })();
  }, []);

  if (!pageContext || !store) {
    return null;
  }
  return (
    <ReduxProvider store={store}>
      <PageContext.Provider value={pageContext}>
        <Root />
      </PageContext.Provider>
    </ReduxProvider>
  );
}

export default App;
