import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'capitalize';

import {formatNumberWithCommas} from '../../../../lib/node_utils';
import {DropdownMenuButton} from '../../DropdownMenuButton.react';
import {Toolbar, ToolbarItem} from '../../common/Toolbar';
import ListingQuery, {ListingSearchResultFacets} from '../../../../lib/search/ListingQuery';
import Button from '../../common/Button';
import Sizer from '../../common/Sizer';
import Configuration from '../../../../lib/configuration';
import GenericFacetFilter from './GenericFacetFilter';
import RangeDropdownFilter from './RangeDropdownFilter';
import ListingFilterDialog from './ListingFilterDialog';
import LocationFilter from './LocationFilter';
import TextFilter from './TextFilter';
import Labeled from './Labeled';
import {
  countSetValues,
  formatPrice,
  OPEN_HOUSE_ITEMS,
  NEW_LISTING_DATE_ITEMS,
} from '../../../../lib/support/search';

class ListingFilterBar extends React.Component {
  static propTypes = {
    query: PropTypes.instanceOf(ListingQuery).isRequired,
    facets: PropTypes.instanceOf(ListingSearchResultFacets),
    onUpdateQuery: PropTypes.func.isRequired,
    onResetQuery: PropTypes.func,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this._locationRequired = Configuration.get('ui.search.require_location') === true;
    this._videos = Configuration.get('ui.search.videos') === true;
    this._photos = Configuration.get('ui.search.hide_photos_filter') !== true;
    this._virtual_tours = Configuration.get('ui.search.show_virtual_tour_filter') === true;
    this._nearest_schools = Configuration.get('ui.search.show_nearest_schools_filter') === true;
    this._subdivision = Configuration.get('ui.search.show_subdivision_filter') === true;
    this.state = {
      showDialog: false,
    };
  }

  render() {
    const {query, facets: f, onUpdateQuery, onResetQuery} = this.props;
    const facets = f.facets;
    const showType = ListingQuery.getListingTypes().length > 1;
    const showFeatures = (Configuration.get('ui.search.show_feature_ids') || []).length > 0;

    const filterBar = (
      <div className="ListingFilterBar">
        <Labeled label="Find near">
          <LocationFilter {...{query, facets, onUpdateQuery}} required={this._locationRequired} />
        </Labeled>

        {showType && (
          <Labeled label="Category">
            <GenericFacetFilter
              {...{query, facets, onUpdateQuery}}
              attrName="type"
              notResettable
              onlyKeys={ListingQuery.getListingTypes()}
              mutex
            />
          </Labeled>
        )}

        {!!Configuration.get('ui.search.sections') && (
          <Labeled label="Section">
            <GenericFacetFilter
              {...{query, facets, onUpdateQuery}}
              attrName="section"
              unsetLabel={Configuration.get('ui.search.sections.default')}
              onlyKeys={Configuration.get('ui.search.sections.options')}
              notResettable
              mutex
            />
          </Labeled>
        )}

        <Labeled label="Type">
          <GenericFacetFilter
            {...{query, facets, onUpdateQuery}}
            attrName="property_type"
            multiSelect
            ignoreEmpty
          />
        </Labeled>

        <Labeled label="Beds">
          <GenericFacetFilter
            {...{query, facets, onUpdateQuery}}
            attrName="bedrooms"
            rangeKey="bedrooms_range.max"
            formatter={formatNumberWithCommas}
          />
        </Labeled>

        <Labeled label="Baths">
          <GenericFacetFilter {...{query, facets, onUpdateQuery}} attrName="bathrooms" />
        </Labeled>

        {query.get('type') === 'for_sale' ? (
          <Labeled label="Price">
            <RangeDropdownFilter
              {...{query, facets, onUpdateQuery}}
              attrName="price"
              formatter={formatPrice}
            />
          </Labeled>
        ) : (
          <Labeled label="Rent">
            <RangeDropdownFilter
              {...{query, facets, onUpdateQuery}}
              attrName="rent"
              formatter={formatPrice}
            />
          </Labeled>
        )}

        <div className="ListingFilterBar_button">
          <DropdownMenuButton
            zIndex={1005}
            ref={(node) => (this._moreDropdownNode = node)}
            label={this._formatMoreLabel()}>
            <div className="ListingFilterBar_more_menu">
              <div className="ListingFilterBar_more_menu_columns">
                <div className="ListingFilterBar_more_menu_columns_column">
                  {this._nearest_schools && (
                    <Labeled label="Schools">
                      <TextFilter
                        {...{query, onUpdateQuery}}
                        attrName="nearest_schools"
                        placeholder="Name of school"
                      />
                    </Labeled>
                  )}

                  {this._subdivision && (
                    <Labeled label="Subdivision">
                      <TextFilter
                        {...{query, onUpdateQuery}}
                        attrName="subdivision"
                        placeholder="Name of subdivision"
                      />
                    </Labeled>
                  )}

                  <Labeled label="Square footage">
                    <RangeDropdownFilter
                      {...{query, facets, onUpdateQuery}}
                      zIndex={1006}
                      attrName="square_footage"
                    />
                  </Labeled>

                  <Labeled label="Lots">
                    <RangeDropdownFilter
                      {...{query, facets, onUpdateQuery}}
                      zIndex={1006}
                      attrName="lots"
                    />
                  </Labeled>

                  {showFeatures && (
                    <Labeled label="Required Features">
                      <GenericFacetFilter
                        {...{query, facets, onUpdateQuery}}
                        attrName="required_features"
                        facetName="features"
                        ignoreKeys={query.get('exclude_features')}
                        zIndex={1006}
                        notResettable
                        multiSelect
                      />
                    </Labeled>
                  )}

                  {showFeatures && (
                    <Labeled label="Exclude Features">
                      <GenericFacetFilter
                        {...{query, facets, onUpdateQuery}}
                        attrName="exclude_features"
                        facetName="features"
                        ignoreKeys={query.get('required_features')}
                        zIndex={1006}
                        notResettable
                        multiSelect
                      />
                    </Labeled>
                  )}

                  {this._photos && (
                    <Labeled label="Photos">
                      <GenericFacetFilter
                        {...{query, facets, onUpdateQuery}}
                        zIndex={1006}
                        attrName="has_photos"
                        normalizer={(v) => !!v}
                        ignoreKeys={['false']}
                      />
                    </Labeled>
                  )}

                  {this._videos && (
                    <Labeled label="Videos">
                      <GenericFacetFilter
                        {...{query, facets, onUpdateQuery}}
                        zIndex={1006}
                        attrName="has_videos"
                        normalizer={(v) => !!v}
                        ignoreKeys={['false']}
                      />
                    </Labeled>
                  )}

                  {this._virtual_tours && (
                    <Labeled label="Virtual Tours">
                      <GenericFacetFilter
                        {...{query, facets, onUpdateQuery}}
                        zIndex={1006}
                        attrName="has_virtual_tours"
                        normalizer={(v) => !!v}
                        ignoreKeys={['false']}
                      />
                    </Labeled>
                  )}
                </div>

                <div className="ListingFilterBar_more_menu_columns_column">
                  {!Configuration.get('data.open_houses.disabled') && (
                    <Labeled label="Open house">
                      <GenericFacetFilter
                        {...{query, facets, onUpdateQuery}}
                        zIndex={1006}
                        attrName="open_house_dates"
                        items={OPEN_HOUSE_ITEMS}
                      />
                    </Labeled>
                  )}

                  <Labeled label="New listings">
                    <GenericFacetFilter
                      {...{query, facets, onUpdateQuery}}
                      zIndex={1006}
                      attrName="publishing_timeframe"
                      items={NEW_LISTING_DATE_ITEMS}
                    />
                  </Labeled>

                  {!!Configuration.get('ui.search.status_filter') && (
                    <Labeled label="Status">
                      <GenericFacetFilter
                        {...{query, facets, onUpdateQuery}}
                        attrName="status"
                        onlyKeys={Configuration.get('ui.search.status_filter.options')}
                        multiSelect
                        notResettable
                        zIndex={1006}
                        formatter={(s) => capitalize.words(s).replace(/_/g, ' ')}
                      />
                    </Labeled>
                  )}

                  <Labeled label="Listing ID">
                    <TextFilter
                      {...{query, facets, onUpdateQuery}}
                      placeholder="E.g. MLS number"
                      attrName="listing_id"
                    />
                  </Labeled>

                  {!!Configuration.get('ui.search.show_year_built_filter') && (
                    <Labeled label="Year Built (or newer)">
                      <TextFilter
                        {...{query, facets, onUpdateQuery}}
                        placeholder="E.g. 1984"
                        attrName="year_built"
                        validator={(value) => value === '' || /^\d{4}$/.test(value)}
                      />
                    </Labeled>
                  )}

                  <Labeled label="Description">
                    <TextFilter {...{query, onUpdateQuery}} attrName="text" />
                  </Labeled>
                </div>
              </div>

              <div className="ListingFilterBar_more_buttons">
                <Button
                  label="Close"
                  kind="flat"
                  size="big"
                  onClick={() => this._moreDropdownNode.close()}
                />
              </div>
            </div>
          </DropdownMenuButton>
        </div>

        {this.props.onResetQuery && (
          <div className="ListingFilterBar_button">
            <Button
              label="Reset"
              onClick={onResetQuery}
              enabled={query.canReset()}
              kind="flat"
              size="big"
            />
          </div>
        )}
      </div>
    );

    return (
      <Sizer
        child={({width}) =>
          width === 0 || width > 900 ? (
            filterBar
          ) : (
            <div className="ListingSearch_tools">
              <ListingFilterDialog
                query={query}
                facets={f}
                onUpdateQuery={onUpdateQuery}
                showType={showType}
                visible={this.state.showDialog}
                onClose={() => this.setState({showDialog: false})}
              />

              <Toolbar
                items={[
                  <ToolbarItem onClick={() => this.setState({showDialog: true})} icon="filter">
                    Filter
                  </ToolbarItem>,

                  <ToolbarItem stretch />,

                  query.canReset() && (
                    <ToolbarItem onClick={() => onResetQuery()}>Reset</ToolbarItem>
                  ),
                ]}
              />
            </div>
          )
        }
      />
    );
  }

  _formatMoreLabel() {
    const {query} = this.props;
    const count = countSetValues(query, [
      'square_footage',
      'lots',
      'has_photos',
      'open_house_dates',
      'publishing_timeframe',
      'listing_id',
      'year_built',
      'text',
    ]);
    if (count > 0) {
      return <span>{count} more</span>;
    }
    return <span>More</span>;
  }
}

export default ListingFilterBar;
