// https://ads.cordlessmedia.com/ad-wrapper/doc/index.html
import Configuration from '../configuration';

const setup = (() => {
  let done = false;
  return () => {
    if (done) {
      return;
    }
    done = true;
    window.cmWrapper = window.cmWrapper || {};
    window.cmWrapper.que = window.cmWrapper.que || [];
    const cordlessId = Configuration.get('ads.cordless_pid');
    if (!cordlessId) {
      return;
    }
    const script = document.createElement('script');
    script.src = `https://ads.cordlessmedia.com/ad-wrapper/${cordlessId}/cm.min.js`;
    document.head.appendChild(script);
  };
})();

const adUnits = {
  search_results_leaderboard: 'listing_result_row',
  search_results_tile_fixed: 'listing_result_tile',
  search_results_tile_fluid: null, // not supported
  listing_detail_tile: 'listing_detail_page_300x250',
  homepage_story_tile: null, // not supported
  home_partner_leaderboard: 'index_page_middle',
  home_branded_leaderboard: null, // not supported
  home_branded_tile: null, // not supported
  homepage_bottom: null, // not supported
  content_leaderboard: null, // not supported
  content_skyscraper: null, // not supported
};

const create = (domId, adName, adNameOverride, targeting) => {
  if (window.cmWrapper.que) {
    const adUnit = adUnits[adName];
    if (!adUnit) {
      console.error(`miswired cordless ad ${adName} at ${domId}`);
      return;
    }
    window.cmWrapper.que.push(() => {
      window.cmWrapper.ads.define(adUnit, domId, Object.entries(targeting));
      window.cmWrapper.ads.requestUnits([domId]);
    });
    return () => {
      window.cmWrapper.que.push(function () {
        window.cmWrapper.ads.destroyUnits([domId]);
      });
    };
  }
};

const vendor = {setup, create};
export default vendor;
