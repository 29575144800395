// https://developers.google.com/publisher-tag/guides/get-started

const sizes = {
  search_results_leaderboard: {
    size: [728, 90],
    sizeMapping: [
      [[800, 0], [[728, 90]]],
      [[0, 0], [[320, 50]]],
    ],
  },
  search_results_tile_fixed: {
    size: [300, 250],
  },
  search_results_tile_fluid: {
    size: 'fluid',
  },
  listing_detail_tile: {
    size: [300, 250],
  },
  homepage_story_tile: {
    size: [300, 250],
  },
  home_partner_leaderboard: {
    size: [970, 90],
    sizeMapping: [
      [
        [970, 0],
        [
          [970, 90],
          [728, 90],
        ],
      ],
      [[728, 0], [[728, 90]]],
      [[0, 0], [[320, 50]]],
    ],
  },
  home_branded_leaderboard: {
    size: [970, 90],
    sizeMapping: [
      [
        [970, 0],
        [
          [970, 90],
          [728, 90],
        ],
      ],
      [[728, 0], [[728, 90]]],
      [[0, 0], [[320, 50]]],
    ],
  },
  home_branded_tile: {
    size: [300, 250],
  },
  homepage_bottom: {
    size: [320, 50],
  },
  content_leaderboard: {
    size: [970, 90],
    sizeMapping: [
      [[970, 0], [[970, 90]]],
      [[728, 0], [[728, 90]]],
      [[0, 0], [[320, 50]]],
    ],
  },
  content_skyscraper: {
    size: [300, 600],
    sizeMapping: [
      [
        [0, 0],
        [
          [300, 600],
          [160, 600],
          [120, 600],
        ],
      ],
    ],
  },
};

const createSlot = (googletag, domId, adName, adNameOverride, targeting) => {
  const config = sizes[adName];
  if (!config) {
    console.error(`no sizes for google ad ${adName} at ${domId}`);
    return;
  }
  if (!adNameOverride) {
    console.error(`missing slot name for google ad ${adName} at ${domId}`);
    return;
  }
  const slot = googletag.defineSlot(adNameOverride, config.size, domId);
  if (config.sizeMapping) {
    const sm = googletag.sizeMapping();
    config.sizeMapping.forEach((row) => {
      sm.addSize(row[0], row[1]);
    });
    slot.defineSizeMapping(sm.build());
  }

  slot.updateTargetingFromMap(targeting);
  slot.addService(googletag.pubads());

  if (!googletag.pubadsReady) {
    googletag.enableServices();
  }

  googletag.display(slot.getSlotElementId());
  if (googletag.pubads().isInitialLoadDisabled()) {
    googletag.pubads().refresh([slot]);
  }
  return slot;
};

const create = (domId, adName, adNameOverride, targeting) => {
  let mounted = true;
  let slot;
  window.googletag.cmd.push(() => {
    if (!mounted) {
      return;
    }
    window.googletag.cmd.push(() => {
      slot = createSlot(window.googletag, domId, adName, adNameOverride, targeting);
    });
  });
  return () => {
    mounted = false;
    window.googletag.cmd.push(() => {
      if (slot) {
        const tmp = slot;
        slot = null;
        window.googletag.destroySlots([tmp]);
      }
    });
  };
};

const vendor = {create};
export default vendor;
