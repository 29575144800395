import React from 'react';
import PropTypes from 'prop-types';

import styles from './SearchTabs.module.scss';
import {formatNumberWithCommas} from '../../../lib/node_utils';
import {ListingSearchResultSet} from '../../../lib/search/ListingQuery';

export class SearchTabs extends React.Component {
  static propTypes = {
    resultSet: PropTypes.instanceOf(ListingSearchResultSet),
  };

  render() {
    const {exactCount} = this.props.resultSet;

    const tabs = [
      {
        label: <span>Search {this._renderCount(exactCount)}</span>,
        prefix: '/search',
      },
    ];

    // FIXME: Crappy active link detection
    return (
      <div className={styles.root}>
        {tabs.map(({label, prefix}, idx) => (
          <div data-active={window.location.pathname.startsWith(prefix)} key={idx}>
            <div className={styles.label}>{label}</div>
          </div>
        ))}
      </div>
    );
  }

  _renderCount(size) {
    return size > 0 && <span className={styles.count}>{formatNumberWithCommas(size)}</span>;
  }
}
